<template>
  <div class="admin-parent-div">
    <div class="pd-16" style="height: 100%; overflow: auto">
      <!-- 表格 -->
      <el-table
        border
        :data="indexList"
        style="width: 100%"
        height="540px"
        class="mt-16"
      >
        <el-table-column prop="name" label="指标名" min-width="180" />
        <el-table-column prop="cname" label="中文名" min-width="180" />
        <el-table-column prop="unit" label="单位" min-width="180" />
        <el-table-column label="操作" min-width="180">
          <template slot-scope="{ row }">
            <el-tooltip content="修改" placement="top">
              <el-popconfirm
                title="是否修改当前指标信息"
                icon="el-icon-edit-outline"
                icon-color="#409EFF"
                placement="top-end"
                @confirm="updateAdmin(row)"
              >
                <a slot="reference" class="icon-btn">
                  <i class="el-icon-edit-outline p8" style="color: #409eff" />
                </a>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 人员编辑弹出框 -->
    <el-dialog
      :visible.sync="adminDialog"
      width="60vw"
      top="3%"
      :close-on-click-modal="false"
      @closed="closeDialog"
    >
      <span slot="title"> 编辑指标 </span>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        size="small"
        class="demo-ruleForm"
      >
        <el-form-item label="指标名" prop="name">
          <span>{{ ruleForm.name }}</span>
        </el-form-item>
        <el-form-item label="中文名" prop="cname">
          <el-input v-model="ruleForm.cname" maxlength="16" show-word-limit />
        </el-form-item>
        <el-form-item label="指标" prop="unit">
          <el-input v-model="ruleForm.unit" maxlength="16" show-word-limit />
        </el-form-item>
        <el-form-item>
          <center>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              style="width: 200px"
              >保 存</el-button
            >
          </center>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { indexDictList, updateIndexDict } from "@/api/index-dict-api";
export default {
  data() {
    return {
      indexList: [],
      adminDialog: false,
      ruleForm: {
        name: "",
        cname: "",
        unit: "",
      },
      rules: {
        cname: [
          { required: true, message: "指标中文名不能为空", trigger: "blur" },
        ],
        unit: [{ required: true, message: "单位不能为空", trigger: "blur" }],
      },
    };
  },
  created() {
    this.queryData();
  },
  methods: {
    queryData() {
      indexDictList().then(({ data, code }) => {
        this.indexList = [];
        if (code === 200 && data) {
          this.indexList = data;
        }
      });
    },
    updateAdmin(row) {
      this.ruleForm = JSON.parse(JSON.stringify(row));
      this.adminDialog = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateIndexDict(this.ruleForm).then(({ code, message }) => {
            if (code === 200) {
              this.$message.success("保存成功");
              this.closeDialog();
              this.queryData();
            } else {
              this.$message.error("保存失败, " + message);
            }
          });
        }
      });
    },
    closeDialog() {
      this.ruleForm = {
        name: "",
        cname: "",
        unit: "",
      };
      this.$refs["ruleForm"].resetFields();
      this.adminDialog = false;
    },
  },
};
</script>

<style scoped>
.admin-parent-div {
  width: 100%;
  height: calc(100vh - 115px);
  overflow: hidden;
  /* background: #fff; */
}
.insert-btn-center {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
